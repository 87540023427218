import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

/**Import Assets */
import styles from "./styles/ProtectedLayout.module.scss";
// import cssStyles from './styles/notification.module.css';

/**Import Custom Component */
import { WEB_ROUTES } from "../../constants";
import { baseRoute } from "../../Helper";
import Navbar from "./Navbar";

// Icons
import { BsPostageFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { FaSackDollar } from "react-icons/fa6";
import { IoIosSettings } from "react-icons/io";
import { IoNotifications } from "react-icons/io5";
import { MdDashboard, MdReport } from "react-icons/md";
import { TbLogout2 } from "react-icons/tb";
import { useGetDashboardDetailsQuery } from "../../Data/services/dashboardApi";
import { removeToken } from "../../Data/services/localStorageService";
import Toast from "../../Hooks/useToast";
import LogoutModal from "../Modals/LogoutModal";

export default function ProtectedLayout({
  isSearchBar,
  isSettingButton,
  selectedRoute,
  children,
}) {
  const [collapsed, setCollapsed] = useState(window.innerWidth > 991);

  // inject data
  const { data } = useGetDashboardDetailsQuery({
    refetchOnMountOrArgChange: true,
  });

  const [logoutModal, setLogoutModal] = useState(false);

  const navigate = useNavigate();
  // todo: menu
  const items = [
    {
      label: "Dashboard",
      icon: <MdDashboard />,
    },
    {
      label: "User Management",
      icon: <FaUser />,
    },
    {
      label: "Post Management",
      icon: <BsPostageFill />,
    },
    {
      label: "Post Report Management",
      icon: <MdReport />,
    },
    {
      label: "Subscription Management",
      icon: <FaSackDollar />,
    },
    {
      label: "Send Notification",
      icon: <IoNotifications />,
    },
    {
      label: "Settings",
      icon: <IoIosSettings />,
    },
  ];

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClose = () => {
    setCollapsed(false);
  };

  const handleViewProfile = () => {
    navigate(baseRoute(WEB_ROUTES.PROFILE));
  };

  const handleLogout = () => {
    try {
      removeToken();
      navigate("/account");
      window.location.reload();
      Toast("Logged Out Successfully", "success");
    } catch {
      Toast("An error occurred during log out", "error");
    }
  };

  const handleLogoutModalOpen = () => {
    setLogoutModal(true);
  };

  const handleLogoutModalClose = () => {
    setLogoutModal(false);
  };

  return (
    <div className={styles.ProtectedLayout}>
      <div
        className={collapsed ? styles.background : ""}
        onClick={handleMenuClose}
      ></div>
      <div
        className={`${styles.menu} ${
          collapsed ? `${styles.collapsed} visible` : styles.notCollapsed
        }`}
        id="sider"
      >
        <div
          className={`${styles.logo} ${!collapsed ? styles.notCollapsed : ""}`}
          to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}
        >
          <img
            src={"https://missingpeoples-app.s3.us-east-2.amazonaws.com/image_2024_09_09T08_24_03_082Z.png"} //todo:
            alt="Logo"
            className={
              collapsed ? styles.imageCollapsed : styles.imageNotCollapsed
            }
            width={"100%"}
            // height={"60%"}
          />
        </div>

        {/* <div className={`${styles.drawer} ${collapsed ? styles.collapsed : ''}`}>

                    <span onClick={handleToggle}>
                        <BiLogOut />
                    </span>
                </div> */}

        {items.map(({ icon, label }, index) => {
          return (
            <Link
              to={`${process.env.REACT_APP_DOMAIN_DIR}/${label
                .toLowerCase()
                .replace(/[^A-Z0-9]/gi, "-")}`}
              className={`${styles.item} ${
                index === selectedRoute ? styles.active : ""
              }`}
              key={index}
            >
              <span style={{ fontSize: "20px", display: "flex" }}>
                {" "}
                {icon}{" "}
              </span>
              {collapsed ? <p>{label}</p> : ""}
            </Link>
          );
        })}
        <Link
          className={styles.item}
          // onClick={handleLogout}
          onClick={handleLogoutModalOpen}
          // onClick={() =>
          //   submit("Confirm Logout", "Are you sure you want to logout?", () =>
          //     handleLogout()
          //   )
          // }
        >
          {/* <img src={logout} alt="Icon1" width={20} height={20} /> */}
          <span style={{ fontSize: "20px", display: "flex" }}>
            <TbLogout2 />
          </span>
          {collapsed ? <p>Logout</p> : ""}
        </Link>
      </div>
      <div className={styles.routeContent}>
        {logoutModal ? (
          <LogoutModal
            handleLogoutModalClose={handleLogoutModalClose}
            data={""}
            onLogout={handleLogout}
          />
        ) : null}

        <Navbar
          isSearchBar={isSearchBar}
          isSettingButton={isSettingButton}
          handleToggle={handleToggle}
        />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}
